'use client';

import type {FC} from 'react';
import {FiShoppingCart} from 'react-icons/fi';
import clsx from 'clsx';
import Link from 'next/link';

import {useCartStore} from '@/hooks/useCartStore';
import useStore from '@/hooks/useStore';
import {cn} from '@/shared';
import {CART_PAGE_PATH} from '@/shared/constants';

type Props = {
	className?: string;
};

export const CartLink: FC<Props> = ({className}) => {
	const cart = useStore(useCartStore, (state) => state.cart);

	let productQuantity = 0;

	if (cart) {
		productQuantity = cart.reduce((acc, item) => acc + item.count, 0);
	}

	return (
		<div>
			<Link
				href={CART_PAGE_PATH}
				className={cn([
					'bg-secondary-dark',
					'text-secondary-light',
					'flex',
					'items-center',
					'justify-center',
					'size-[38px]',
					'rounded-full',
					'relative',
					className,
				])}
			>
				{productQuantity && productQuantity > 0 ? (
					<>
						<span className="sr-only">Handlevogn med {productQuantity} produkter</span>
						<CartCount items={productQuantity} />
						<FiShoppingCart aria-hidden className="mr-[3px] mt-[2px]" />
					</>
				) : (
					<>
						<span className="sr-only">Handlevogn</span>
						<FiShoppingCart aria-hidden className="mr-[2px] mt-[2px]" />
					</>
				)}
			</Link>
		</div>
	);
};

type CartCountProps = {
	items: number;
};

const CartCount: FC<CartCountProps> = ({items}) => {
	return (
		<span
			className={clsx(
				'absolute',
				'-top-2',
				'-right-2',
				'bg-red-700',
				'size-[24px]',
				'rounded-full',
				'flex',
				'justify-center',
				'items-center',
				'text-sm',
			)}
		>
			{items}
		</span>
	);
};
