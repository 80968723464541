import clsx from 'clsx';
import Image from 'next/image';
import Link from 'next/link';

import {Container} from '@/components/container';
import type {KeyedArray} from '@/shared/types';

import {CartLink} from '../cart/cart-link';

import {DesktopNav} from './DesktopNav';
import type {DropdownType} from './Dropdown';
import {MobileNav} from './MobileNav';
import type {NavLinkType} from './NavLink';

export type NavigationType = KeyedArray<NavLinkType | DropdownType>;

type NavbarProps = {
	navigation: NavigationType;
};

const FRONT_PAGE = 'Forside';

export const Navbar = ({navigation}: NavbarProps) => {
	return (
		<Container as="header" className={clsx('relative', 'z-50', 'bg-primary')}>
			<div className={clsx('flex', 'h-20', 'items-center', 'justify-between', 'gap-4')}>
				<Link href="/">
					<span className={clsx('sr-only')}>{FRONT_PAGE}</span>
					{/* <Logo aria-hidden="true" height={40} /> */}
					<Image src="/logo.svg" alt="" width={120} height={40} />
				</Link>
				<div className={clsx('flex', 'items-center', 'gap-10')}>
					{navigation ? (
						<>
							<DesktopNav navigation={navigation} />
							<MobileNav navigation={navigation} />
						</>
					) : null}
					<CartLink />
				</div>
			</div>
		</Container>
	);
};
