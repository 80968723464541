import {createClient} from '@sanity/client';

import {apiVersion, dataset, projectId} from './api';

export const client = createClient({
	projectId,
	dataset,
	apiVersion,
	useCdn: false,
	perspective: 'published',
});

export const clientWritable = createClient({
	projectId,
	dataset,
	apiVersion,
	useCdn: false,
	perspective: 'published',
	token: process.env.SANITY_EDITOR_API_TOKEN,
});
