'use client';

import {type FC} from 'react';
import {SanityProjectDetailsProvider} from '@selvklart/sanity-next-image';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';

interface Props {
	children: React.ReactNode;
}

const projectDetails = {
	dataset: process.env.NEXT_PUBLIC_SANITY_DATASET ?? '',
	projectId: process.env.NEXT_PUBLIC_SANITY_PROJECT_ID ?? '',
};

const queryClient = new QueryClient();

export const AppProvider: FC<Props> = ({children}) => {
	return (
		<SanityProjectDetailsProvider projectDetails={projectDetails}>
			<QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
		</SanityProjectDetailsProvider>
	);
};
