import {create} from 'zustand';
import {persist} from 'zustand/middleware';

type Product = {
	productId: string;
	priceId: string;
};

export type CartItem = Product & {
	count: number;
};

export type CartState = {
	cart: CartItem[];
};

export type CartActions = {
	incrementItem: (id: string) => void;
	decrementItem: (id: string) => void;
	setItemCount: (id: string, count: number) => void;
	add: (product: Product) => void;
	removeItem: (id: string) => void;
	removeAll: () => void;
	count: () => number;
};

export type CartStore = CartState & CartActions;

export const useCartStore = create<CartStore>()(
	persist(
		(set, get) => ({
			cart: [],
			count: (): number => {
				const {cart} = get();
				if (cart.length) {
					return cart.map((item) => item.count).reduce((a, b) => a + b);
				}
				return 0;
			},
			add: (product: Product) => {
				const {cart} = get();
				const updatedCart = addToCart(cart, product);
				set({cart: updatedCart});
			},
			removeItem: (id: string) => {
				const {cart} = get();
				const updatedCart = removeFromCart(cart, id);
				set({cart: updatedCart});
			},
			removeAll: () => {
				set({cart: []});
			},
			incrementItem: (id: string) => {
				const {cart} = get();
				const updatedCart = incrementInCart(cart, id);
				set({cart: updatedCart});
			},
			decrementItem: (id: string) => {
				const {cart} = get();
				const updatedCart = decrementInCart(cart, id);
				set({cart: updatedCart});
			},
			setItemCount: (id: string, count: number) => {
				const {cart} = get();
				const updatedCart = setCountInCart(cart, id, count);
				set({cart: updatedCart});
			},
		}),
		{name: 'cart-storage'},
	),
);

const addToCart = (cart: CartItem[], product: Product): CartItem[] => {
	const item = cart.find((item) => item.productId === product.productId);

	if (item) {
		return cart.map((item) => {
			if (item.productId === product.productId) {
				const itemCount = item.count >= 1 ? item.count : 1;
				return {...item, count: itemCount};
			}
			return item;
		});
	}

	return [...cart, {...product, count: 1}];
};

const removeFromCart = (cart: CartItem[], id: string): CartItem[] => {
	const item = cart.find((item) => item.productId === id);
	if (item) {
		return cart.filter((item) => item.productId !== id);
	}
	return cart;
};

const incrementInCart = (cart: CartItem[], id: string): CartItem[] => {
	const item = cart.find((item) => item.productId === id);
	if (item) {
		return cart.map((item) => {
			if (item.productId === id) {
				return {...item, count: item.count + 1};
			}
			return item;
		});
	}
	return cart;
};

const decrementInCart = (cart: CartItem[], id: string): CartItem[] => {
	const item = cart.find((item) => item.productId === id);
	if (item) {
		return cart.map((item) => {
			if (item.productId === id) {
				const itemCount = item.count > 1 ? item.count - 1 : 1;
				return {...item, count: itemCount};
			}
			return item;
		});
	}
	return cart;
};

const setCountInCart = (cart: CartItem[], id: string, count: number): CartItem[] => {
	const item = cart.find((item) => item.productId === id);
	if (item) {
		return cart.map((item) => {
			if (item.productId === id) {
				const itemCount = count >= 1 ? count : 1;
				return {...item, count: itemCount};
			}
			return item;
		});
	}
	return cart;
};
